import * as React from "react"
import { Link } from 'gatsby';
import styled from "styled-components"
import tw from 'twin.macro';


export const HeroText = styled.div`
    ${tw`font-gibson md:text-5xl text-2xl leading-normal md:leading-tight`}
    span {
        ${tw`font-medium`}
    }
`

export const HeroSubTitle = tw.h3`text-base md:text-lg font-sans`

export const CardContain = tw.div`grid md:grid-cols-2 gap-7 mx-7 my-14 border-t border-black border-opacity-10 pt-14`;


export const CadenceButton = ({ children, to }) => <Link to={ to } tw="flex-grow-0 text-center mt-6 inline-block bg-red-500 rounded-button p-3 px-6 text-white text-sm font-medium hover:bg-red-400 transition-colors">{ children }</Link>


export const FormContain = styled.div`


  input[type="text"] {
    padding: 8px;
    border-bottom: 1px solid #b2b2b2;
    margin-bottom: 24px;
  }

  input[type="email"] {
    padding: 8px;
    border-bottom: 1px solid #b2b2b2;
    margin-bottom: 24px;
  }

  select {
    padding: 12px;
  }

  textarea {
    padding: 8px;
    border-bottom: 1px solid #b2b2b2;
    margin-bottom: 24px;
  }

`
