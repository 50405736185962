import * as React from "react"
import Layout from '../components/Layout'
import tw from 'twin.macro' /* eslint-disable-line */

import { HeroSubTitle, FormContain } from '../styles/base'

const ContactPage = () => {
  return (
    <Layout>
    <div tw='bg-gray-200 pt-8 px-7'>
      <HeroSubTitle tw='max-w-3xl  mt-5 text-center mx-auto px-7 md:px-5 mb-16 font-gibson'>
        Contact Adjmi Apparel
      </HeroSubTitle>

      <div tw='grid md:grid-cols-2 max-w-3xl mx-auto'>
        <div>
          Design, Merchandising<br />
          and Administrative Offices:<br />
          Adjmi Apparel Group<br />
          463 7th Avenue<br />
          New York, New York 10018<br />
          Phone: 212.629.9600<br />
          
        </div>
        <div tw="mt-8 md:mt-0">
             Innovative Fitness Group<br />
             463 7th Ave, 5th Floor<br />
             New York, NY 10018<br />
             Phone: 212.239.8615<br />          
        </div>
      </div>


      <FormContain>
        <form name='Contact Page' action='/thanks' method="POST" data-netlify="true" encType='application/x-www-form-urlencoded'>
        <div tw='flex flex-col max-w-3xl mx-auto space-y-3 py-8'>
          <input type="hidden" name="form-name" value="Contact Page" />
          <label htmlFor='first-name'>First Name</label>
          <input type='text' name='first-name' />
          <label htmlFor='last-name'>Last Name</label>
          <input type='text' name='last-name' />
          <label htmlFor='email'>Email</label>
          <input type='email' name='email' />
          <label htmlFor='subject'>Subject</label>
          <input type='text' name='subject' />
          <label htmlFor='message'>Message</label>
          <textarea name='message' rows="10"></textarea>
          <input name='submit' tw="block mt-16 w-48 bg-gray-700 text-white mx-auto rounded cursor-pointer" type='submit' value='Send' />
        </div>
        </form>
      </FormContain>

    </div>

    </Layout>
  )
}

export default ContactPage
